import React from 'react'
import Core from '../templates/Core'
import styled from "styled-components";
import Helmet from 'react-helmet'
import Header from '../page_fragments/Header'
import { PrimaryButton } from '../design_system/components/buttons'
class Contact extends React.Component {
    render() {

        const formWrapper = ({ className, children, name }) => (
            <form className={className} data-netlify="true" name={name}>
                {children}
            </form>
        );

        const labelWrapper = ({ className, children }) => (
            <label className={className} >
                {children}
            </label>
        );

        const inputWrapper = ({ className, inputType, name }) => (
            <input className={className} type={inputType} name={name} />
        );

        const PageWrapper = styled.div`
            display: flex;
            flex-direction: column;
            color: ${props => props.theme.textColor};
            text-align: center;
            width:100%
        `

        const FormWrapper = styled(formWrapper)`
            display: flex;
            flex-direction: column;
            margin: auto;
            width:95%;
            text-align:center;
            max-width:400px;
        `
        const FormElement = styled.div`
            margin: auto;
            padding: .75em;
            text-align: center;
            width:100%
        `
        const FormLabel = styled(labelWrapper)`
            margin: auto;
            text-align: left;
            width: 70%
        `
        const FormSmallTextInput = styled(inputWrapper)`
            text-align:left;
            width: 100%;
        `
        const FormLargeTextInput = styled(inputWrapper)`
            text-align:left;
            height: 8em;
            width: 100%;
        `
        const FormEmailInput = styled(inputWrapper)`
            text-align:left;
            width: 100%;
        `

        return (
            <Core>
                <Helmet>
                    <title>Justin Isbell-Contact</title>
                    <meta charSet="utf-8" />
                    <meta name="description" content="You can contact Justin Isbell Here!"></meta>
                    <link rel="canonical" href="https://www.justinisbell.org/contact" />
                </Helmet>
                <Header />
                <PageWrapper>
                    <main>
                        <h1>Contact</h1>
                        <p>You can contact me at my email or through this form!</p>
                        <FormWrapper name="contact">
                            <input type="hidden" name="form-name" value="contact" />
                            <FormElement>
                                <FormLabel>
                                    <div>First Name</div>
                                    <FormSmallTextInput inputType="text" name="firstName" />
                                </FormLabel>
                            </FormElement>
                            <FormElement>
                                <FormLabel>
                                    <div>Last Name</div>
                                    <FormSmallTextInput inputType="text" name="lastName" />
                                </FormLabel>
                            </FormElement>
                            <FormElement>
                                <FormLabel>
                                    <div>Email</div>
                                    <FormEmailInput inputType="email" name="email" />
                                </FormLabel>
                            </FormElement>
                            <FormElement>
                                <FormLabel>
                                    <div>Note</div>
                                    <FormLargeTextInput inputType="text" name="note" />
                                </FormLabel>
                            </FormElement>
                            <FormElement>
                                <PrimaryButton type="submit">Submit</PrimaryButton>
                            </FormElement>
                        </FormWrapper>
                    </main>
                </PageWrapper>
            </Core>
        )
    }
}

export default Contact
